import { GridColDef } from "@mui/x-data-grid";

export const clientColumns: GridColDef[] = [
  {
    field: "fullname",
    headerName: "Nome do cliente",
    minWidth: 270,
    filterable: true,
  },
  { field: "email", headerName: "E-mail", minWidth: 180 },
  { field: "phone1", headerName: "Telefone", minWidth: 140 },
  {
    field: "emergencyname",
    headerName: "Contato de Emergência",
    minWidth: 140,
  },
  { field: "emergencyphone", headerName: "Telefone Emergência", minWidth: 220 },
  { field: "emergencyemail", headerName: "Email de Emergência", minWidth: 200 },
];
