import { QueryClient } from "react-query";

import { QueryClient as ReactQueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient();

export const reactQueryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 1000 * 60 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: false,
    },
  },
});
