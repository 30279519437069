import { useMutation } from "@tanstack/react-query";
import { Travel, TravelReceive } from "types/data/Travel";
import { create as createTravel } from "api/requests/travel/create";
import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";
import { toast } from "react-toastify";

export const useCreateTravel = () => {
  return useMutation({
    mutationKey: ["create-travel"],
    mutationFn: (travelData: Travel | TravelReceive) =>
      createTravel(removeEmptyStringAttributes(travelData)),
    onError: (error: any) =>
      toast.error(`Erro ao cadastrar viagem ${error.message}`),
  });
};
