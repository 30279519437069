import { useEffect, useState } from "react";
import { Input } from "components/Inputs";

type InputProps = React.ComponentProps<typeof Input>;

const TimeInput: React.FC<InputProps> = ({ value, onChange, ...props }) => {
  const [internalValue, setInternalValue] = useState(value || "");

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  const handleOnInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value.replace(/\D/g, "");

    if (inputValue.length > 4) {
      inputValue = inputValue.slice(0, 4);
    }

    let formattedValue = inputValue;
    if (inputValue.length >= 3) {
      formattedValue = `${inputValue.slice(0, 2)}:${inputValue.slice(2, 4)}`;
    }

    setInternalValue(formattedValue);
    onChange?.(formattedValue);
  };

  const handleOnBlur = () => {
    if (internalValue.length === 5) {
      const [hours, minutes] = internalValue.split(":").map(Number);

      let finalValue = internalValue;
      if (hours > 23) finalValue = "23:" + internalValue.slice(3, 5); // Limita horas a 23
      if (minutes > 59) finalValue = internalValue.slice(0, 3) + "59"; // Limita minutos a 59

      setInternalValue(finalValue);
      onChange?.(finalValue);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Tab/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Input
      {...props}
      value={internalValue}
      onInput={handleOnInput}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      placeholder="HH:MM"
      maxLength={5}
      type="normal"
      color="white"
      radius={100}
    />
  );
};

export default TimeInput;
