import { useQuery } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import { reactQueryClient } from "api/queryClient";
import search from "api/requests/travel/search";

import { Travel } from "types/data/Travel";
import { formatedDateToShow, numberToCurrency } from "utils/formatters";

export const useSearchTravel = (travelId: number) => {
  return useQuery({
    queryKey: ["travels-selected", travelId],
    queryFn: async () => {
      if (travelId !== 0) {
        const response = await search({ id: travelId });
        const travel = response[0];
        const startDate = formatedDateToShow(travel.startdate);
        const endDate = formatedDateToShow(travel.enddate);
        const budget = numberToCurrency(Number(travel.budget));

        return {
          startDay: startDate?.slice(0, 2),
          startMonth: startDate?.slice(3, 5),
          startYear: startDate?.slice(6, 11),
          endDay: endDate?.slice(0, 2),
          endMonth: endDate?.slice(3, 5),
          endYear: endDate?.slice(6, 11),
          budget,
          ...travel,
        } as Travel;
      }
      queryClient.invalidateQueries(["travels-list", travelId]);
      reactQueryClient.invalidateQueries({
        queryKey: ["travels-list", travelId],
      });
      return {} as Travel;
    },
  });
};
