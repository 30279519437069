import React, { useEffect, useState } from "react";
import { InputsForm } from "../types";
import {
  Container,
  BirthDateContent,
  ButtonsSection,
  ContentForm,
  DateInputs,
  PhoneContainer,
  Section,
  TopContainer,
  TableContainer,
  Header,
  SearchContainer,
  SeachField,
  ProfilePicture,
  ImgContainer,
} from "../Register/styles";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MaskedInput } from "components/Inputs/Masks";
import { Input } from "components/Inputs";
import { Button } from "components/Buttons";
import { DataGrid } from "@mui/x-data-grid";
import { ILimit } from "pages/Travel/Register/types";
import { NEW_ITEM, useSelectedIdContext } from "context/SelectedIdContext";
import { useQueries, useMutation } from "react-query";
import { search as getTravels } from "../../../api/requests/travel/search";
import { findOne as getUser } from "api/requests/user/findOne";
import { TravelReceive } from "types/data/Travel";
import { ReceiveUser, User } from "types/data/User";
import { schema } from "./schemas";
import { update as updateUser } from "../../../api/requests/user/update";

import { formatedDateOfRequest, formatedDateToShow } from "utils/formatters";
import { queryClient } from "api/queryClient";
import { toast } from "react-toastify";
import { registerPasswordRequest } from "api/requests/user/redefinePassword";
import { ProfileSVG, SearchSVG } from "assets/svg/components";
import { travelColumns } from "./schemas";
import { handleSetStatus } from "utils/getTravelStatus";
import { useNavigate } from "react-router-dom";
import { ButtonWrapper } from "pages/Travel/Register/style";
import { InputLabel } from "components/Inputs/styles";
import { Stack } from "@mui/material";
import { IconButton } from "../List/Table/styles";
import { TagButtonContainer } from "pages/Profile/Main/styles";

export default function Client() {
  const [isEditFields, setIsEditFields] = useState(false);
  const [search, setSearch] = React.useState("");
  const limit = 10;
  const [selectedIds, setSelectedIds] = React.useState<React.Key[]>([]);
  const [getUserData, setGetUserData] = useState<ReceiveUser>();
  const [data, setData] = React.useState<TravelReceive[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<InputsForm>({
    resolver: yupResolver(schema),
  });

  const { selectedId } = useSelectedIdContext();

  const searchParamValue =
    typeof selectedId !== "undefined" ? String(selectedId) : String(0);

  const [travelsQuery, userQuery] = useQueries([
    {
      queryKey: ["travels-selected", 1],
      queryFn: () => getTravels({ user_id: parseInt(searchParamValue) }),
    },
    {
      queryKey: ["user-selected", 2],
      queryFn: () => getUser(parseInt(searchParamValue)),
    },
  ]);

  const handleSetFormatData = () => {
    const queryDataFormated = travelsQuery?.data?.map((item) => {
      const players = item.users_travels?.length;

      const startDateFormat = formatedDateToShow(item.startdate);
      const endDateFormat = formatedDateToShow(item.enddate);
      const statusReceive = handleSetStatus(item.startdate, item.enddate);
      const formatData: TravelReceive = {
        ...item,
        users: players,
        enddate: endDateFormat,
        startdate: startDateFormat,
        status: statusReceive,
      };
      return { ...formatData };
    });
    const tableData = queryDataFormated ? queryDataFormated : [];
    setData(tableData);
  };
  const handleSetUser = () => {
    userQuery.data?.map((item) => {
      const stringDate = formatedDateToShow(item.birthdate);
      const phone1Formated = item.phone1;
      const emergencyPhoneFormated = item.emergencyphone;
      const phone2Formated = item.phone2;
      const day = stringDate.slice(0, 2);
      const month = stringDate.slice(3, 5);
      const year = stringDate.slice(6, 11);

      setGetUserData({
        ...item,
        day: day,
        month: month,
        year: year,
        phone1: phone1Formated,
        phone2: phone2Formated,
        emergencyphone: emergencyPhoneFormated,
      });
    });
  };

  const userMutation = useMutation({
    mutationFn: (user: Partial<User>) => updateUser(user),
    onSuccess: (data) => {
      toast.success("Dados Atualizados");
      setSelectedId(NEW_ITEM);
      navigate(-1);
    },
    onError: (error) => {
      toast.error(`Algo deu Errado ${error}`);
    },
  });

  useEffect(() => {
    handleSetUser();
    handleSetFormatData();
  }, [travelsQuery.data, userQuery.data]);

  const onSubmit = (data: InputsForm) => {
    const year = data.year ? data.year : getUserData?.year;
    const day = data.day ? data.day : getUserData?.day;
    const month = data.month ? data.month : getUserData?.month;
    const dateString = `${year}${month}${day}`;
    const formatedData = data.year
      ? formatedDateOfRequest(dateString)
      : undefined;
    const firstPhoneFormated = data.firstPhone;
    const secondPhoneFormated = data.secondPhone;
    const emergencyPhoneFormated = data.emergencyContactPhone;
    userMutation.mutate({
      id: getUserData?.id,
      fullname: data.fullName,
      email: data.email,
      emergencyemail: data.emergencyContactEmail,
      emergencyname: data.emergencyContactName,
      emergencyphone: emergencyPhoneFormated,
      phone1: firstPhoneFormated,
      phone2: secondPhoneFormated,
      birthdate: formatedData,
    });
    setIsEditFields(false);
  };

  const handleSetValues = () => {
    setValue("fullName", getUserData?.fullname ? getUserData?.fullname : "");
    setValue("email", getUserData?.email ? getUserData?.email : "");
    setValue(
      "emergencyContactEmail",
      getUserData?.emergencyemail ? getUserData?.emergencyemail : ""
    );
    setValue(
      "emergencyContactName",
      getUserData?.emergencyname ? getUserData?.emergencyname : ""
    );
    setValue("firstPhone", setMaskedValues().phone1Value);
    setValue("secondPhone", setMaskedValues().phone2Value);
    setValue("emergencyContactPhone", setMaskedValues().emergencyPhone);
  };

  const handleSetEditFields = () => {
    setIsEditFields(!isEditFields);
    handleSetValues();
  };

  const setMaskedValues = () => {
    const dayValue = getUserData?.day ? getUserData?.day : "";
    const yearValue = getUserData?.year ? getUserData?.year : "";
    const monthValue = getUserData?.month ? getUserData?.month : "";
    const phone1Value = getUserData?.phone1 ? getUserData.phone1 : "";
    const phone2Value = getUserData?.phone2 ? getUserData?.phone2 : "";
    const emergencyPhone = getUserData?.emergencyphone
      ? getUserData?.emergencyphone
      : "";

    return {
      dayValue,
      yearValue,
      monthValue,
      phone1Value,
      phone2Value,
      emergencyPhone,
    };
  };
  const { setSelectedId } = useSelectedIdContext();
  const navigate = useNavigate();

  const handleTravelButtonClicked = () => {
    setSelectedId(selectedId);
    navigate("/viagens/viagem");
  };
  const { isLoading, mutate, isSuccess } = useMutation({
    mutationFn: (email: string | undefined) => {
      const response = registerPasswordRequest(email);

      return response;
    },
    onSuccess: (data: any) => {
      toast.success("Usuário Criado");
      queryClient.invalidateQueries("clients-list");
    },
    onError: (error) => {
      toast.error("Algo deu Errado");
    },
  });
  const OpenDataButton = () => {
    if (selectedId?.valueOf && selectedIds.length == 1) {
      return (
        <ButtonWrapper>
          <Button onClick={handleTravelButtonClicked} color="orange">
            Abrir
          </Button>
        </ButtonWrapper>
      );
    } else {
      return <span></span>;
    }
  };
  const handleRegisterPassword = () => {
    mutate(getUserData?.email);
  };
  return (
    <Container>
      <ContentForm onSubmit={handleSubmit(onSubmit)}>
        <Header>
          {getUserData?.profilepicture ? (
            <ProfilePicture src={getUserData?.profilepicture} />
          ) : (
            <ImgContainer>
              <ProfileSVG width={50} height={50} />
            </ImgContainer>
          )}
          <h1>{getUserData?.fullname}</h1>
          <TagButtonContainer>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                handleSetEditFields();
              }}
            >
              Editar Perfil
            </IconButton>
          </TagButtonContainer>
        </Header>
        <TopContainer>
          <Input
            error={{
              message: errors.fullName?.message,
              showMessage: true,
            }}
            register={{ ...register("fullName", { required: true }) }}
            name="fullName"
            label="Nome completo"
            placeholder={getUserData?.fullname}
            color={isEditFields ? "white" : ""}
            disable={!isEditFields}
            defaultValue={getUserData?.fullname}
            size="90%"
          />

          <Input
            register={{ ...register("email") }}
            error={{
              message: errors.email?.message,
              showMessage: true,
            }}
            name="email"
            label="Email"
            inputType="email"
            defaultValue={getUserData?.email}
            placeholder={getUserData?.email}
            color={isEditFields ? "white" : ""}
            disable={!isEditFields}
          />
        </TopContainer>
        <Section>
          <BirthDateContent>
            <InputLabel>Data de nascimento:</InputLabel>
            <DateInputs>
              <MaskedInput
                reset={reset}
                setValue={() => setValue("day", setMaskedValues().dayValue)}
                register={{ ...register("day") }}
                error={{
                  message: errors.day?.message,
                  showMessage: true,
                }}
                option="day"
                name="day"
                id="day"
                placeholder={getUserData?.day}
                size="50px"
                color={isEditFields ? "white" : ""}
                disable={!isEditFields}
              />

              <MaskedInput
                reset={reset}
                setValue={() => setValue("month", setMaskedValues().monthValue)}
                register={{ ...register("month") }}
                error={{
                  message: errors.month?.message,
                  showMessage: true,
                }}
                option="month"
                id="month"
                placeholder={getUserData?.month}
                size="50px"
                color={isEditFields ? "white" : ""}
                disable={!isEditFields}
              />

              <MaskedInput
                reset={reset}
                setValue={() => setValue("year", setMaskedValues().yearValue)}
                register={{ ...register("year") }}
                error={{
                  message: errors.year?.message,
                  showMessage: true,
                }}
                option="year"
                id="year"
                placeholder={getUserData?.year}
                size="120px"
                color={isEditFields ? "white" : ""}
                disable={!isEditFields}
              />
            </DateInputs>
          </BirthDateContent>
          <PhoneContainer>
            <MaskedInput
              reset={reset}
              register={{ ...register("firstPhone", { required: true }) }}
              error={{
                message: errors.firstPhone?.message,
                showMessage: true,
              }}
              id="firstPhone"
              option="phone"
              label="Telefone 1"
              placeholder="Telefone 1"
              color={isEditFields ? "white" : ""}
              disable={!isEditFields}
              setValue={() => setValue("firstPhone", getUserData?.phone1 || "")}
            />

            <MaskedInput
              reset={reset}
              setValue={() =>
                setValue("secondPhone", setMaskedValues().phone2Value)
              }
              register={{ ...register("secondPhone") }}
              id="secondPhone"
              option="phone"
              label="Telefone 2"
              placeholder={getUserData?.phone2}
              color={isEditFields ? "white" : ""}
              disable={!isEditFields}
            />
          </PhoneContainer>
        </Section>
        <h1>Contatos de emergência</h1>
        <Section>
          <Input
            register={{
              ...register("emergencyContactName"),
            }}
            error={{
              message: errors.emergencyContactName?.message,
              showMessage: true,
            }}
            name="emergencyContactName"
            label="Nome"
            placeholder={getUserData?.emergencyname}
            defaultValue={getUserData?.emergencyname}
            color={isEditFields ? "white" : ""}
            disable={!isEditFields}
          />
          <MaskedInput
            reset={reset}
            setValue={() =>
              setValue(
                "emergencyContactPhone",
                setMaskedValues().emergencyPhone
              )
            }
            register={{
              ...register("emergencyContactPhone"),
            }}
            error={{
              message: errors.emergencyContactPhone?.message,
              showMessage: true,
            }}
            id="emergencyContactPhone"
            option="phone"
            label="Telefone"
            placeholder={getUserData?.emergencyphone}
            color={isEditFields ? "white" : ""}
            disable={!isEditFields}
          />

          <Input
            register={{
              ...register("emergencyContactEmail"),
            }}
            error={{
              message: errors.email?.message,
              showMessage: true,
            }}
            name="emergencyContactEmail"
            label="Email"
            inputType="email"
            placeholder={getUserData?.emergencyemail}
            defaultValue={getUserData?.emergencyemail}
            color={isEditFields ? "white" : ""}
            disable={!isEditFields}
          />
        </Section>
        <h1>Viagens Cadastradas</h1>
        <SearchContainer>
          <SearchSVG />
          <SeachField
            type="text"
            placeholder="Pesquisar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </SearchContainer>
        <OpenDataButton />
        <TableContainer>
          <DataGrid
            loading={travelsQuery.isLoading}
            onCellDoubleClick={(params, event) => {
              if (!event.ctrlKey) {
                setSelectedId(params.row.id);
                navigate("/viagens/viagem");
                event.defaultMuiPrevented = true;
              }
            }}
            style={{
              paddingTop: 5,
              paddingLeft: 5,
              borderRadius: 10,
              border: "none",
              height: "100%",
              width: "100%",
              outline: "none",
              fontFamily: "Poppins",
              color: "#646464",
            }}
            rows={
              search
                ? data.filter((item: TravelReceive) =>
                    item.destination
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  )
                : data
            }
            checkboxSelection
            pageSize={limit}
            rowsPerPageOptions={[5]}
            columns={travelColumns}
            onSelectionModelChange={(newSelection) => {
              setSelectedId(newSelection[0]);
              setSelectedIds(newSelection.map((item) => item));
            }}
            components={{
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nada para ver aqui
                </Stack>
              ),
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nada para ver aqui
                </Stack>
              ),
            }}
          />
        </TableContainer>
        <ButtonsSection>
          {isEditFields && <Button buttonType="submit">Pronto</Button>}
          <Button color="green" onClick={handleRegisterPassword}>
            Cadastrar senha
          </Button>
        </ButtonsSection>
      </ContentForm>
    </Container>
  );
}
