import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: 20px;
  overflow-y: scroll;
  width: 100%;
  padding-bottom: 4rem;
  @media (max-width: 700px) {
    height: 100%;
    display: flex;
  }
`;

export const ButtonWrapper = styled.div<{
  buttonColor?: string;
}>`
  & button {
    background-color: ${({ theme, buttonColor }) =>
      buttonColor ? buttonColor : theme.palette.green.main};
    border: none;
    outline: none;
    display: flex;
    width: fit-content;
    gap: 8px;
    align-items: center;
    width: 100px;
    border-radius: 5px;
    padding: 0.3rem 1rem;
    height: 50px;
    :hover {
      background-color: ${({ theme, buttonColor }) =>
        buttonColor ? theme.palette.orange.main : theme.palette.green._200};
    }
  }
  & {
    svg {
      width: 18px;
      transition: 0.5s;
    }
    span {
      color: white;
      font-size: 16px;
      font-weight: bold;
    }
  }
  :hover {
    svg {
      rotate: 90deg;
    }
  }
  button > h1 {
    font-size: 0.8rem;
  }
`;

export const PerfilInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
`;

export const InfoTexts = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 2rem;
  }
`;

export const TagButtonContainer = styled.div`
  margin-top: -25px;
`;

export const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const DateInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
`;

export const ErrorMessage = styled.span`
  color: red;
`;

export const PasswordWrapper = styled.div``;

export const ErrorAndInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const PeriodContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const BudgetWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;
export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  max-width: 400px;

  svg {
    margin-right: -10px;
    width: 20px;
  }
`;
export const SeachField = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
export const TableContainer = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  border: "none";
  border-radius: 10;
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }
`;
export const DeleteWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  outline: none;
  background: transparent;
  margin-right: 60px;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.grey.main};
      transition: 0.2s;
    }
  }
  span {
    color: ${({ theme }) => theme.palette.grey.main};
  }
  :hover {
    cursor: pointer;
  }
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.palette.orange.main};
      }
    }
    span {
      color: ${({ theme }) => theme.palette.orange.main};
    }
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: ;
  width: 100%;
`;
export const HeaderButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const OpenButtonWrapper = styled.div`
  button {
    border-radius: 10px;
    padding: 0 1rem;
  }
`;
