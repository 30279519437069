import { useMutation } from "react-query";
import { remove as removeTravelActivity } from "api/requests/travelActivity/remove";
import { remove as removeActivity } from "api/requests/activity/remove";
import { queryClient, reactQueryClient } from "api/queryClient";
import { useSelectedIdContext } from "context/SelectedIdContext";
import { toast } from "react-toastify";

export const useDeleteTravelActivity = () => {
  const { selectedId: travelId } = useSelectedIdContext();
  return useMutation({
    mutationKey: ["delete-travel-activity"],
    mutationFn: ({
      travelActivityId,
      activity_id,
    }: {
      travelActivityId: number;
      activity_id: number;
    }) => removeTravelActivity(travelActivityId),
    onSuccess: async (data, variables) => {
      await removeActivity(variables.activity_id);
      queryClient.invalidateQueries({
        queryKey: ["list-travel-activities", travelId],
      });
      reactQueryClient.invalidateQueries({
        queryKey: ["list-travel-activities", travelId],
      });
    },
    onError: (error) => {
      toast.error(
        `Algo deu errado e não foi possível deletar a atividade: ${error}`
      );
    },
  });
};
