import * as yup from "yup";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { formatedDateToShow } from "utils/formatters";

export const schema = yup.object().shape({
  activityTitle: yup.string().required("Título é obrigatório"),
  activityType: yup.string().optional(),
  activityDescription: yup.string().optional(),
  activityAddress: yup.string().optional(),
  activityComment: yup.string().optional(),
  startsat: yup.string().optional(),
  endsat: yup.string().optional(),
  activityOrder: yup.string().optional(),
  activityBudget: yup.string().optional(),
  activitydate: yup.string(),
  isvisible: yup.boolean(),
});

export const activitiesCollum: GridColDef[] = [
  {
    field: "title",
    headerName: "Título",
    minWidth: 270,
    valueGetter: (params) => params.row.activities.title,
  },
  {
    field: "activitydate",
    headerName: "Data",
    minWidth: 180,
    valueFormatter: (params) =>
      params.value ? formatedDateToShow(params.value) : "Sem data cadastrada",
  },
  { field: "ordenation", headerName: "Ordem", minWidth: 140 },
  {
    field: "activity_type",
    headerName: "Tipo",
    minWidth: 200,
    valueGetter: (params) => params.row.activities.activity_type,
  },
  {
    field: "startsat",
    headerName: "Início",
    minWidth: 150,
    valueGetter: (params) => params.row.activities.startsat,
  },
  {
    field: "endsat",
    headerName: "Término",
    minWidth: 150,
    valueGetter: (params) => params.row.activities.endsat,
  },
  {
    field: "isvisible",
    headerName: "Visibilidade",
    minWidth: 190,
    renderCell: (params: GridRenderCellParams<string>) => {
      const paramsValue = params.value;
      const handleFieldColor = () => {
        if (paramsValue) {
          return "#075D68";
        }
        return "#F1896E";
      };

      return (
        <strong>
          <>
            <span
              style={{ color: handleFieldColor(), fontSize: "1.5rem" }}
              tabIndex={params.hasFocus ? 0 : -1}
            >
              {params.value ? "VISÍVEL" : "INVISÍVEL"}
            </span>
          </>
        </strong>
      );
    },
  },
];
