import { Input } from "components/Inputs";

interface DateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  valueType: "day" | "month" | "year";
  inputSize: string;
  register: any;
}

const DateInputMask: React.FC<DateInputProps> = ({
  valueType,
  inputSize = "20px",
  ...props
}) => {
  const valueTypes = {
    day: {
      min: "1",
      max: "31",
      step: "1",
      placeholder: "DD",
    },
    month: {
      min: "1",
      max: "12",
      step: "1",
      placeholder: "MM",
    },
    year: {
      min: "1",
      max: "2999",
      step: "1",
      placeholder: "YYYY",
    },
  };

  const { min, max, step, placeholder } = valueTypes[valueType];

  const handleOnInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/\D/g, "");

    if (value === "") {
      event.target.value = "";
      return;
    }

    if (valueType !== "year") {
      if (value.length > 2) value = value.slice(0, 2);
      if (Number(value) > Number(max)) value = max;
    } else {
      if (value.length > 4) value = value.slice(0, 4);
      if (Number(value) < Number(min)) value = min;
      if (Number(value) > Number(max)) value = max;
    }

    event.target.value = value;
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "." || event.key === "," || event.key === "e") {
      event.preventDefault();
    }
  };

  return (
    <Input
      {...props}
      min={min}
      max={max}
      step={step}
      placeholder={placeholder}
      type="normal"
      onInput={handleOnInput}
      onKeyDown={handleOnKeyDown}
      inputType="number"
      color="white"
      size={inputSize}
      radius={100}
    />
  );
};

export default DateInputMask;
