import React, { useState, useEffect } from "react";
import { Button } from "components/Buttons";
import Input from "components/Inputs/Normal";
import Checklist from "./components/Checklist";
import {
  Container,
  FormContent,
  DateContent,
  InputLabel,
  SwitchWrapper,
  BudgetWrapper,
  TableContainer,
  SaveButtonWrapper,
  DoubleItemsWrapper,
  ErrorAndInputContainer,
} from "./style";
import { DataGrid, GridSelectionModel, GridToolbar } from "@mui/x-data-grid";
import { MaskedInput } from "components/Inputs/Masks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { currencyToNumber, numberToCurrency } from "utils/formatters";
import { ILimit, InputsForm } from "./types";
import ActivitiesForm from "./components/ActivitiesForm";
import Tips from "./components/Tips";
import { useMutation } from "react-query";
import { Travel, TravelReceive } from "types/data/Travel";

import { reactQueryClient as queryClient } from "api/queryClient";
import { toast } from "react-toastify";
import SwitchInput from "components/Inputs/Switch";
import Images from "./Images";
import { Divider, Stack } from "@mui/material";
import { clientColumns } from "pages/Clients/List/Table/Schemas";
import { useSelectedIdContext } from "context/SelectedIdContext";

import update from "api/requests/travel/update";

import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";
import { useSearchTravel } from "pages/Travel/Register/hooks/queries/travel/useSearchTravel";
import { useListClients } from "pages/Travel/Register/hooks/queries/travel/useListClients";
import updateTravelUsers from "api/requests/travel/updateTravelUsers";
import { useCreateTravel } from "pages/Travel/Register/hooks/queries/travel/useCreateTravel";

const limit: ILimit = 10;

const TravelRegister = () => {
  // TODO: separar toda a lógica em um hook dedicado
  const { selectedId: travelId, setSelectedId: setTravelId } =
    useSelectedIdContext();

  // const [travelId, setTravelId] = useState<number>(Number(selectedId));

  const { data: travel, isLoading: isLoadingTravel } = useSearchTravel(
    Number(travelId)
  );

  const { data: clientsList, isLoading: isLoadingClientsList } =
    useListClients();

  const [selectedsClients, setSelectedsClients] = useState<any>([]);

  const [isVisible, setIsVisible] = React.useState<boolean>(
    travel?.isvisible || false
  );

  // TODO: mover essa mutation para outro arquivo
  const travelUpdateReq = useMutation({
    mutationFn: (travelData: Travel | TravelReceive) => update(travelData),
    onSuccess: (data) => {
      if (data.id) {
        setTravelId(data.id);
      }
      toast.success("Viagem atualizada");
      queryClient.invalidateQueries({ queryKey: "travels-list" });
      queryClient.invalidateQueries({
        queryKey: ["travels-selected", travelId],
      });
    },
    onError: (error) => {
      toast.error(`Erro ao atualizar a viagem ${error}`);
    },
  });

  const { handleSubmit, setValue, control, register } = useForm<InputsForm>({
    defaultValues: {
      title: travel?.title,
      description: travel?.description,
      budget: numberToCurrency(Number(travel?.budget)),
      destination: travel?.destination,
      startDay: travel?.startDay,
      startMonth: travel?.startMonth,
      startYear: travel?.startYear,
      endDay: travel?.endDay,
      endMonth: travel?.endMonth,
      endYear: travel?.endYear,
    },
  });

  const handleFormatData = (data: InputsForm) => {
    const formatBudget = currencyToNumber(data.budget);
    const startDate = `${data.startYear}-${data.startMonth}-${data.startDay}`;
    const endDate = `${data.endYear}-${data.endMonth}-${data.endDay}`;
    let travel: TravelReceive = {
      ...data,
      budget: String(formatBudget),
      enddate: endDate,
      startdate: startDate,
      isvisible: isVisible,
    };

    return travel;
  };

  const { mutate: createTravel } = useCreateTravel();

  const onSubmit: SubmitHandler<InputsForm> = (data: InputsForm) => {
    const requestData = removeEmptyStringAttributes(handleFormatData(data));

    if (travelId) {
      travelUpdateReq.mutate({ ...requestData, id: Number(travelId) });
      addAndRemoveUsersOfTravel(Number(travelId));
    } else {
      createTravel(requestData, {
        onSuccess: (data) => {
          if (data.id) {
            setTravelId(data.id);
            addAndRemoveUsersOfTravel(Number(data.id));
          }
          toast.success("Viagem cadastrada");
          queryClient.invalidateQueries({ queryKey: "travels-list" });
          queryClient.invalidateQueries({
            queryKey: ["travels-selected", travelId],
          });
        },
      });
    }
  };

  const [usersHasToUpdate, toggleUsersHasToUpdate] = useState(false);

  const handleSelectionModelChange = (
    newSelectionModel: GridSelectionModel
  ) => {
    toggleUsersHasToUpdate(true);
    setSelectedsClients(newSelectionModel);
  };

  function addAndRemoveUsersOfTravel(id: number) {
    if (usersHasToUpdate) {
      const usersID = clientsList?.map((item) => item.id);
      if (usersID && usersID.length > 0) {
        updateTravelUsers(id, { remove: [...new Set(usersID)] });
      }
      if (selectedsClients.length > 0) {
        console.log("usuários que vão viajar: ", selectedsClients);
        updateTravelUsers(id, {
          add: [...new Set(selectedsClients)] as number[],
        });
      }
      toggleUsersHasToUpdate(false);
    }
  }

  useEffect(() => {
    if (
      clientsList &&
      clientsList?.length > 0 &&
      travel?.users_travels &&
      travel?.users_travels?.length > 0
    ) {
      const usersTravel = new Set(
        travel?.users_travels?.map((client) => client.user_id)
      );

      setSelectedsClients(
        clientsList
          ?.filter((client) => usersTravel.has(client.id))
          .map((client) => client.id) || []
      );
    }
  }, [clientsList, travel?.users_travels]);

  useEffect(() => {
    if (!isLoadingTravel && travel) {
      setValue("title", travel?.title || "");
      setValue("description", travel?.description || "");
      setValue("budget", numberToCurrency(Number(travel?.budget)) || "");
      setValue("destination", travel?.destination || "");
      setValue("startDay", travel?.startDay || "");
      setValue("startMonth", travel?.startMonth || "");
      setValue("startYear", travel?.startYear || "");
      setValue("endDay", travel?.endDay || "");
      setValue("endMonth", travel?.endMonth || "");
      setValue("endYear", travel?.endYear || "");
      setIsVisible(travel.isvisible);
    }
  }, [travel, isLoadingTravel, setValue]);

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <h1> {travel?.title ? "Editar Viagem" : "Cadastrar Viagem"}</h1>
        <FormContent>
          <ErrorAndInputContainer>
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Título:*"
                  color="white"
                  placeholder="Título da Viagem"
                  size="550px"
                />
              )}
            />

            <Controller
              control={control}
              name="destination"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Destino: "
                  color="white"
                  placeholder="Destino"
                  size="400px"
                />
              )}
            />
          </ErrorAndInputContainer>

          <ErrorAndInputContainer>
            <DateContent>
              <InputLabel>Data de início*:</InputLabel>
              <DoubleItemsWrapper>
                <MaskedInput
                  register={{ ...register("startDay") }}
                  setValue={() =>
                    travel?.startDay &&
                    setValue(
                      "startDay",
                      travel?.startDay || new Date().getUTCDate().toString()
                    )
                  }
                  option="day"
                  name="startDay"
                  id="startDay"
                  color="white"
                  placeholder="DD"
                  size="50px"
                />

                <MaskedInput
                  register={{ ...register("startMonth") }}
                  setValue={() =>
                    travel?.startMonth &&
                    setValue(
                      "startMonth",
                      travel?.startMonth || new Date().getUTCMonth().toString()
                    )
                  }
                  option="month"
                  id="startMonth"
                  name="startMonth"
                  color="white"
                  placeholder="MM"
                  size="50px"
                />

                <MaskedInput
                  register={{ ...register("startYear") }}
                  setValue={() =>
                    travel?.startYear &&
                    setValue(
                      "startYear",
                      travel?.startYear ||
                        new Date().getUTCFullYear().toString()
                    )
                  }
                  option="year"
                  id="startYear"
                  name="startYear"
                  color="white"
                  placeholder="AAAA"
                  size="120px"
                />
              </DoubleItemsWrapper>
            </DateContent>

            <DateContent>
              <InputLabel>Data de encerramento*:</InputLabel>
              <DoubleItemsWrapper>
                <MaskedInput
                  register={{ ...register("endDay") }}
                  setValue={() =>
                    travel?.endDay &&
                    setValue(
                      "endDay",
                      travel?.endDay || new Date().getUTCDate().toString()
                    )
                  }
                  option="day"
                  name="endDay"
                  id="endDay"
                  color="white"
                  placeholder="DD"
                  size="50px"
                />

                <MaskedInput
                  register={{ ...register("endMonth") }}
                  setValue={() =>
                    travel?.endMonth &&
                    setValue(
                      "endMonth",
                      travel?.endMonth || new Date().getUTCMonth().toString()
                    )
                  }
                  option="month"
                  id="endMonth"
                  name="endMonth"
                  color="white"
                  placeholder="MM"
                  size="50px"
                />

                <MaskedInput
                  register={{ ...register("endYear") }}
                  setValue={() =>
                    travel?.endYear &&
                    setValue(
                      "endYear",
                      travel?.endYear || new Date().getUTCFullYear().toString()
                    )
                  }
                  option="year"
                  id="endYear"
                  name="endYear"
                  color="white"
                  placeholder="AAAA"
                  size="120px"
                />
              </DoubleItemsWrapper>
            </DateContent>
          </ErrorAndInputContainer>

          <ErrorAndInputContainer>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <Input
                  {...field}
                  color="white"
                  placeholder="Descrição"
                  label="Descrição"
                  name="description"
                  size="450px"
                />
              )}
            />

            <SwitchWrapper>
              <InputLabel>Visibilidade: </InputLabel>
              <SwitchInput
                options={["Invisível", "Visível"]}
                checked={isVisible}
                handleChange={() => setIsVisible(!isVisible)}
              />
            </SwitchWrapper>
          </ErrorAndInputContainer>

          <BudgetWrapper>
            <MaskedInput
              register={{ ...register("budget") }}
              setValue={() =>
                travel?.budget &&
                setValue(
                  "budget",
                  numberToCurrency(Number(travel?.budget) || 0)
                )
              }
              label="Orçamento"
              id="budget"
              option="money"
              color="white"
              placeholder="custo da viagem"
              size="245px"
            />
          </BudgetWrapper>

          <Divider
            style={{
              background: "#e3e3e3",
              height: "0.5px",
              width: "100%",
              opacity: 0.4,
            }}
          />
          {setValue && (
            <Images
              defaultValue={travel?.countdownimagelinks}
              setValue={setValue}
            />
          )}
          <Divider
            style={{
              margin: "10px 0",
              background: "#e3e3e3",
              height: "0.5px",
              width: "100%",
              opacity: 0.4,
            }}
          />
          <InputLabel>Participantes: </InputLabel>
        </FormContent>

        <TableContainer style={{ width: "90%" }}>
          <DataGrid
            style={{
              paddingTop: 5,
              paddingLeft: 5,
              borderRadius: 10,
              border: "none",
              height: "700px",
              outline: "none",
            }}
            loading={isLoadingClientsList}
            checkboxSelection
            rows={clientsList || []}
            pageSize={limit}
            rowsPerPageOptions={[5]}
            columns={clientColumns}
            selectionModel={selectedsClients}
            onSelectionModelChange={handleSelectionModelChange}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            components={{
              Toolbar: GridToolbar,
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nada para ver aqui
                </Stack>
              ),
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  Nada para ver aqui
                </Stack>
              ),
            }}
          />
        </TableContainer>
        <SaveButtonWrapper>
          <Button buttonType="submit">Salvar</Button>
        </SaveButtonWrapper>

        {!isNaN(Number(travelId)) && travelId !== 0 && (
          <>
            <Checklist />
            <Tips />
            <ActivitiesForm />
          </>
        )}
      </Container>
    </>
  );
};

export default TravelRegister;
