import { url, treatError, defaultHeaders } from "../../api";

export const remove = async (id: number) => {
  const defaultHeadersReceive = defaultHeaders();
  const headers = { ...defaultHeadersReceive, mode: "no-cors" };

  return fetch(url(`activity/${id}`), {
    headers,
    method: "DELETE",
  }).then((r) => {
    if (r.ok) return r.ok;
    throw new Error(treatError(r));
  });
};

export default remove;
