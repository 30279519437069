import { useQuery } from "@tanstack/react-query";
import { useSelectedIdContext } from "context/SelectedIdContext";
import { search as searchTravelActivities } from "api/requests/travelActivity/search";

export const useListTravelActivities = () => {
  const { selectedId: travelId } = useSelectedIdContext();

  return useQuery({
    queryKey: ["list-travel-activities", travelId],
    queryFn: () => searchTravelActivities({ travel_id: Number(travelId) }),
  });
};
