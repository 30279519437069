import React, { useEffect, useState } from "react";
import { Input } from "components/Inputs";

type InputProps = React.ComponentProps<typeof Input>;

const CurrencyInput: React.FC<InputProps> = ({ value, onChange, ...props }) => {
  const formatCurrency = (rawValue: string | number) => {
    const numericValue = String(rawValue).replace(/\D/g, "");
    const number = Number(numericValue) / 100;
    return number.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const [internalValue, setInternalValue] = useState(
    formatCurrency(value || 0)
  );

  useEffect(() => {
    setInternalValue(formatCurrency(value || 0));
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const formattedValue = formatCurrency(rawValue);

    setInternalValue(formattedValue);

    onChange?.(formattedValue);
  };

  return (
    <Input
      {...props}
      value={internalValue}
      onChange={handleChange}
      placeholder="R$ 0,00"
      type="normal"
      inputType="text"
      color="white"
      radius={100}
    />
  );
};

export default CurrencyInput;
