import { useMutation } from "@tanstack/react-query";
import { removeEmptyStringAttributes } from "utils/removeEmptyStringAttributes";
import { toast } from "react-toastify";
import { create as createActivity } from "api/requests/activity/create";
import { create as createTravelActivity } from "api/requests/travelActivity/create";
import { useSelectedIdContext } from "context/SelectedIdContext";

export const useCreateTravelActivity = () => {
  const { selectedId: travelId } = useSelectedIdContext();

  return useMutation({
    mutationKey: ["create-travel-activity"],
    mutationFn: (item: any) =>
      createActivity(
        removeEmptyStringAttributes({
          startsat: item.startsat,
          endsat: item.endsat,
          title: item.activityTitle,
          activity_type: item.activityType,
          budget: item.activityBudget,
        })
      ),
    onSuccess: (data, variables) =>
      createTravelActivity(
        removeEmptyStringAttributes({
          travel_id: Number(travelId),
          activity_id: Number(data.id),
          description: variables.activityDescription,
          activitydate: variables.activitydate,
          ordenation: variables.activityOrder,
          isvisible: variables.isvisible,
        })
      ),
    onError: (error) =>
      toast.error(
        `Algo deu errado e não foi possível criar a atividade: ${error}`
      ),
  });
};
