import React from "react";
import ErrorMessage from "../ErrorMessage";
import ModalError from "../ModalError";
import {
  Container,
  Icon,
  InputContainer,
  InputLabel,
  StyledInput,
} from "../styles";
import { IInput } from "../types";

interface iNormalInput<T extends string> extends IInput<T> {
  [x: string]: any;
}

const NormalInput = <T extends string>({
  customIcon,
  size,
  radius,
  border,
  error,
  color,
  inputType,
  register,
  disable,
  label = "",
  ...rest
}: iNormalInput<T>) => {
  return (
    <InputContainer>
      <InputLabel>{label && `${label}: `}</InputLabel>
      <Container
        error={error?.message}
        border={border}
        size={size}
        radius={radius}
        color={color}
      >
        {error?.showModalMessage && <ModalError error={error?.message} />}

        {customIcon && (
          <Icon
            initial={{
              opacity: 0,
              x: "-50%",
            }}
            animate={{
              opacity: 1,
              x: "0%",
            }}
            transition={{
              delay: 0.5,
            }}
          >
            {customIcon}
          </Icon>
        )}
        <StyledInput
          initial={{ opacity: 0, scale: 0.5, x: 30 }}
          animate={{ opacity: 1, scale: 1, x: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          disabled={disable}
          {...register}
          type={inputType}
          {...rest}
        />
      </Container>
      {error && <ErrorMessage error={error} />}
    </InputContainer>
  );
};

export default NormalInput;
