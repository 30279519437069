import { useQuery } from "@tanstack/react-query";
import { search as listUsers } from "api/requests/user/search";

export const useListClients = () => {
  return useQuery({
    queryKey: ["list-clients"],
    queryFn: () => {
      return listUsers();
    },
  });
};
