export type Travel = {
  id?: number;
  title?: string;
  description?: string;
  status?: TravelStatusEnum;
  startdate?: string;
  enddate?: string;
  destination?: string;
  budget?: string;
  countdownimagelinks?: string;
  startDay?: string;
  startMonth?: string;
  startYear?: string;
  endDay?: string;
  endMonth?: string;
  endYear?: string;
  users_travels?: Array<{
    id?: number;
    user_id?: number;
    travel_id?: number;
  }>;
  isvisible: boolean;
};
export type TravelReceive = {
  id?: number;
  title?: string;
  description?: string;
  status?: TravelStatusEnum;
  countdownimagelinks?: string;
  startdate?: string;
  enddate?: string;
  destination?: string;
  budget?: string;
  users?:
    | Array<{
        id?: number;
        user_id?: number;
        travel_id?: number;
      }>
    | number;
  isvisible?: boolean;
};
export enum TravelStatusEnum {
  REALIZADA = "Realizada",
  CANCELADA = "Cancelada",
  RASCUNHO = "Rascunho",
  PROGRESSO = "Em progresso",
  AGENDADA = "Agendada",
}
